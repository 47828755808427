import React, { useState } from "react"
import { CookieBanner } from "@palmabit/react-cookie-law"
import Cookies from "js-cookie"

import TagManager from "react-gtm-module"

const CookieNotice = () => {
   const [cookieChoice, setCookieChoice] = useState(
      typeof Cookies.get("rcl_consent_given") !== "undefined" ? true : false
   )

   const handleUpdateCookie = () => {
      Cookies.remove("rcl_consent_given", { path: "" })
      Cookies.remove("rcl_preferences_consent", { path: "" })
      Cookies.remove("rcl_statistics_consent", { path: "" })

      setCookieChoice(false)
   }

   return (
      <div id="cookie-container" className={`show-cookie step-0`}>
         {!cookieChoice ? (
            <CookieBanner
               message={"Ce site utilise de délicieux cookies"}
               policyLink={`/politique-de-confidentialite`}
               privacyPolicyLinkText={`En savoir plus`}
               showDeclineButton={true}
               declineButtonText={`Refuser`}
               acceptButtonText={`Accepter`}
               necessaryOptionText={`Nécessaires`}
               preferencesOptionText={`Fonctionnels`}
               statisticsOptionText={`Statistiques`}
               showMarketingOption={false}
               // Remove default style
               styles={{
                  dialog: {},
                  container: {},
                  message: {},
                  policy: {},
                  selectPane: {},
                  optionWrapper: {},
                  optionLabel: {},
                  checkbox: {},
                  buttonWrapper: {},
                  button: {},
               }}
               onAcceptStatistics={() => {
                  TagManager.initialize({
                     gtmId: "GTM-MD3QX3T",
                  })
               }}
            />
         ) : (
            <button
               className="show-cookie step-0 button-update-cookie"
               onClick={() => handleUpdateCookie()}
            >
               Mettre à jour mes préférences de cookie
            </button>
         )}
      </div>
   )
}

// class CookieNotice extends React.Component {
//    constructor(props) {
//       super(props)

//       this.state = {
//          cookieStep: 0,
//          cookieChoice:
//             typeof Cookies.get("rcl_consent_given") !== "undefined"
//                ? true
//                : false,
//       }

//       this.handleAccept = this.handleAccept.bind(this)
//       this.handleUpdateCookie = this.handleUpdateCookie.bind(this)
//    }

//    componentDidMount() {
//       if (typeof document !== "undefined") {
//          // const eatCookie = () => {
//          //    // Check if all state has been executed
//          //    this.state.cookieStep === 5
//          //       ? this.setState({ cookieStep: 0 })
//          //       : this.setState({ cookieStep: this.state.cookieStep + 1 })
//          // }
//          // let cookieTimer = setInterval(eatCookie, 500);
//       }
//    }

//    handleAccept(e) {
//       this.setState({ cookieChoice: true })

//       console.log("change cookieChoice")
//    }

//    render() {
//       return (

//       )
//    }
// }

export default CookieNotice
