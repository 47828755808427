import React from 'react'

const TAExcellence = () => (
  <svg width={149} height={115}>
    <g fill="none" fillRule="evenodd">
      <path stroke="#05A680" fill="#FFF" d="M.5.5h148v114H.5z" />
      <text
        fontFamily="Arial-BoldMT, Arial"
        fontSize={11}
        fontWeight="bold"
        fill="#252322"
      >
        <tspan x={11.425} y={22}>
          {'Attestation d\u2019Excellence'}
        </tspan>
        <tspan x={62.765} y={34}>
          {'2019'}
        </tspan>
      </text>
      <g fillRule="nonzero">
        <path
          d="M76.767 52.135c-5.823 0-11.083 1.451-15.232 4.134l-6.98.006s1.647 2.176 2.182 4.37a10.897 10.897 0 00-2.176 6.52c0 6.082 5.003 11.022 11.15 11.022 3.511 0 6.644-1.613 8.69-4.13l2.37 3.506 2.394-3.537a11.016 11.016 0 004.092 3.19 11.206 11.206 0 008.528.334c5.768-2.107 8.725-8.464 6.596-14.166a10.882 10.882 0 00-1.512-2.75c.525-2.207 2.192-4.411 2.192-4.411l-7.437-.005c-4.173-2.667-9.242-4.083-14.858-4.083h.001zM53.81 84.298a.883.883 0 00-.885.877.88.88 0 00.885.874.881.881 0 00.885-.874.883.883 0 00-.885-.877zm-9.154.78l-.104.038-1.34.455v1.732h-1.487v1.163h1.443v3.728c0 1.776.612 2.5 2.109 2.5.382 0 .745-.049 1.11-.145l.057-.016-.015-1.203-.105.034c-.292.105-.523.154-.704.154-.7 0-.964-.393-.964-1.45v-3.602h1.718v-1.163h-1.718V85.08zm15.155 2.055c-1.02 0-1.88.482-2.451 1.35v-1.18h-1.361v10.223h1.488V93.56c.537.723 1.308 1.119 2.268 1.119 1.881 0 3.095-1.527 3.095-3.886 0-2.19-1.22-3.662-3.039-3.662zm-8.626.042c-.863 0-1.558.462-1.994 1.307v-1.179h-1.36v7.262h1.488v-3.644c0-1.53.649-2.37 1.824-2.37.17 0 .337.025.537.08l.096.029.048-1.435-.639-.05zm1.883.128v7.262h1.489v-7.262h-1.49zm6.386 1.048c1.15 0 1.839.945 1.839 2.528 0 1.612-.728 2.61-1.896 2.61-1.178 0-1.91-.993-1.91-2.597 0-1.543.772-2.54 1.967-2.54z"
          fill="#000A12"
        />
        <path
          d="M87.567 56.189a11.224 11.224 0 00-3.509.666 11.038 11.038 0 00-6.263 5.736 10.957 10.957 0 00-.982 3.77C76.399 60.729 71.7 56.26 65.93 56.147c3.192-1.35 6.858-2.086 10.833-2.086 4.002 0 7.614.714 10.803 2.129"
          fill="#FCC40F"
        />
        <path
          d="M65.71 58.361c-4.905 0-8.897 3.949-8.897 8.805 0 4.85 3.992 8.8 8.897 8.8 4.904 0 8.894-3.95 8.894-8.8 0-4.856-3.99-8.805-8.894-8.805zm21.592.04a8.945 8.945 0 00-3.054.544 8.803 8.803 0 00-5 4.575 8.668 8.668 0 00-.263 6.733v.001a8.766 8.766 0 004.62 4.945 8.927 8.927 0 006.806.268c4.602-1.686 6.962-6.762 5.265-11.311-1.327-3.56-4.747-5.763-8.374-5.756z"
          fill="#FFF"
        />
        <path
          d="M65.572 61.621c-3.039 0-5.51 2.444-5.51 5.446 0 3.003 2.471 5.448 5.51 5.448 3.03 0 5.498-2.445 5.498-5.448 0-3.002-2.467-5.446-5.498-5.446zm22.328 0c-3.036 0-5.505 2.444-5.505 5.446 0 3.003 2.47 5.448 5.505 5.448 3.034 0 5.502-2.445 5.502-5.448 0-3.002-2.468-5.446-5.502-5.446z"
          fill="#000A12"
        />
        <path
          d="M65.572 63.497c-1.994 0-3.615 1.602-3.615 3.57 0 1.968 1.621 3.574 3.615 3.574 1.985 0 3.601-1.606 3.601-3.574 0-1.968-1.616-3.57-3.601-3.57zm22.328 0c-1.99 0-3.609 1.602-3.609 3.57 0 1.968 1.62 3.574 3.61 3.574 1.988 0 3.605-1.606 3.605-3.574 0-1.968-1.617-3.57-3.606-3.57z"
          fill="#FFF"
        />
        <path
          d="M67.416 67.066c0 1.01-.831 1.834-1.852 1.834a1.845 1.845 0 01-1.853-1.834c0-1.013.828-1.833 1.853-1.833 1.021 0 1.852.82 1.852 1.833"
          fill="#EF6A45"
        />
        <path
          d="M87.9 65.234a1.84 1.84 0 00-1.85 1.833c0 1.01.824 1.834 1.85 1.834 1.023 0 1.85-.825 1.85-1.834a1.84 1.84 0 00-1.85-1.833zM76.274 83.837v4.453c-.548-.756-1.311-1.157-2.296-1.157-1.817 0-3.038 1.484-3.038 3.688 0 2.272 1.236 3.859 3.01 3.859 1.051 0 1.869-.46 2.439-1.356v1.241h1.374V83.837h-1.49zm11.193.46a.882.882 0 00-.885.877.88.88 0 00.885.875.881.881 0 00.884-.875.883.883 0 00-.884-.877zm-20.27 2.836c-.907 0-1.704.17-2.436.52l-.045.02.017 1.257.115-.065c.7-.387 1.36-.585 1.966-.585 1.076 0 1.693.612 1.693 1.679v.061h-.85c-2.449 0-3.799.878-3.799 2.472 0 1.28.973 2.173 2.366 2.173 1.025 0 1.819-.398 2.383-1.148v1.048h1.346V90.17c0-2.013-.926-3.036-2.756-3.036zm32.264 0c-2.237 0-3.627 1.456-3.627 3.802 0 2.345 1.39 3.8 3.627 3.8 2.246 0 3.642-1.455 3.642-3.8 0-2.345-1.396-3.802-3.642-3.802zm8.381.042c-.86 0-1.557.461-1.994 1.307v-1.179h-1.358v7.262h1.487v-3.644c0-1.531.648-2.37 1.825-2.37.17 0 .335.025.535.08l.096.028.05-1.434-.64-.05zm-15.188.044c-1.613 0-2.698.78-2.698 1.946 0 1.023.71 1.562 2.02 2.192.824.404 1.258.64 1.258 1.205 0 .58-.532.956-1.353.956-.585 0-1.23-.164-1.869-.473l-.11-.055-.048 1.28.052.022c.61.239 1.255.36 1.917.36 1.821 0 2.997-.86 2.997-2.188 0-1.109-.746-1.67-2.034-2.265-.945-.437-1.342-.697-1.342-1.16 0-.441.441-.729 1.121-.729.616 0 1.217.135 1.785.402l.105.05.067-1.253-.057-.022a6.595 6.595 0 00-1.811-.268zm-13.981.084l3.597 7.262 3.696-7.262H84.41l-2.11 4.192-1.95-4.192h-1.678zm8.05 0v7.262h1.49v-7.262h-1.49zm12.752.992c1.257 0 2.067 1.03 2.067 2.626 0 1.585-.81 2.61-2.067 2.61-1.235 0-2.066-1.05-2.066-2.61 0-1.569.83-2.626 2.066-2.626zm-25.167.015c1.232 0 1.965.97 1.965 2.597 0 1.55-.76 2.555-1.937 2.555-1.133 0-1.837-.98-1.837-2.555 0-1.577.71-2.597 1.81-2.597zm-6.567 2.747h.765v.445c0 1.251-.76 2.058-1.939 2.058-.718 0-1.222-.468-1.222-1.14 0-.878.851-1.363 2.396-1.363z"
          fill="#00B087"
        />
      </g>
    </g>
  </svg>
)

export default TAExcellence