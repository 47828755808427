import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat'

const Messenger = () => {
   return(
      <MessengerCustomerChat
         pageId="501446976573727"
         appId=""
         themeColor="#1D1D1B"
         loggedInGreeting="Bonjour ! Comment pouvons-nous vous aider ?"
         loggedOutGreeting="Bonjour ! Comment pouvons-nous vous aider ?"
         language="fr_FR"
      />
   )
}



export default Messenger