import React from "react"
import { StaticQuery, graphql, Link } from "gatsby";

// Image
import TAExcellence from "../../../images/TAExcellence"
import TANote from "../../../images/TANote"

// Helpers
import removeDomaine from '../../../helpers/url.helpers'

class Footer extends React.Component {

   render() {
      return(
         <StaticQuery
            query={graphql`
               query {
                  footer: allWordpressAcfOptions {
                     edges {
                        node {
                           wordpress_id
                           informations_sites {
                              ep_facebook
                              ep_instagram
                              ep_twitter
                              ep_phone_link
                              ep_phone
                              ep_payments {
                                 item
                              }
                              ep_address {
                                 address_cp
                                 address_number
                                 address_street
                                 address_town
                              }
                           }
                        }
                     }
                  }
                  menu: allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "footer"}}) {
                     edges {
                        node {
                           items {
                              url
                              title
                           }
                        }
                     }
                  }
               }
            `}
            render={data => {

               let payments = data.footer.edges[0].node.informations_sites.ep_payments
               let footerData = data.footer.edges[0].node.informations_sites

               return(
                  <footer className="site-footer">

                     <div className="subfooter container">
                        <div>
                           <h4>Moyens de paiements acceptés</h4>
                           <ul className="list--unstyled">
                              { payments.map((payment, index) => (
                                 <li key={`paymentItem-${index}`}>{payment.item}</li>
                              ))}
                           </ul>
                        </div>

                        <div className="block-label">
                           <a href="https://fr.tripadvisor.be/Restaurant_Review-g188648-d4738727-Reviews-L_epicerie-Tournai_Hainaut_Province_Wallonia.html" className="item" target="_blank" rel="noopener noreferrer">
                              <TAExcellence />
                           </a>

                           <a href="https://fr.tripadvisor.be/Restaurant_Review-g188648-d4738727-Reviews-L_epicerie-Tournai_Hainaut_Province_Wallonia.html#REVIEWS" className="item" target="_blank" rel="noopener noreferrer">
                              <TANote />
                           </a>
                        </div>

                        <nav className="subfooter-nav">
                           <h4>Suivez-nous</h4>
                           <ul className="list--unstyled">
                              { footerData.ep_facebook && ( <li><a href={footerData.ep_facebook} target="_blank" rel="noopener noreferrer">Facebook</a></li> )}
                              { footerData.ep_instagram && ( <li><a href={footerData.ep_instagram} target="_blank" rel="noopener noreferrer">Instagram</a></li> )}
                              { footerData.ep_twitter && ( <li><a href={footerData.ep_twitter} target="_blank" rel="noopener noreferrer">Twitter</a></li> )}
                           </ul>
                        </nav>

                        <nav className="subfooter-nav">
                           <h4>Réserver</h4>
                           <ul className="list--unstyled">
                              { footerData.ep_phone && ( <li><a href={`tel:${footerData.ep_phone_link}`}>{footerData.ep_phone}</a></li> )}
                              { footerData.ep_facebook && ( <li><a href={footerData.ep_facebook} target="_blank" rel="noopener noreferrer">Facebook Messenger</a></li> )}
                           </ul>

                           <h4>Adresse</h4>
                           <p>{footerData.ep_address.address_street}, {footerData.ep_address.address_number}<br />
                           {footerData.ep_address.address_cp}, {footerData.ep_address.address_town}</p>
                        </nav>
                     </div>

                     <div className="back-to-top container">
                        <a href="#top">
                           <i className="icon icon--arrow-right"></i>
                           <span>Retour en haut</span>
                        </a>
                     </div>

                     <div className="footer container">
                        <div className="footer-nav">
                           <i className="icon icon--glasses"></i>
                           <ul className="list--unstyled">
                              { data.menu.edges[0].node.items.map((item, index) => (
                                 <li key={`navFooter-${index}`}>
                                    <Link to={removeDomaine(item.url)}>{item.title}</Link>
                                 </li>
                              ))}
                           </ul>
                        </div>

                        <div className="block-copyright">

                           <div className="animated-icons">
                              Réalisé avec
                              <div className="icons js-animate-icons">
                                 <div className="icons-container">
                                    <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg"><g stroke="#979797" strokeWidth="2" fill="none" fillRule="evenodd"><path d="M12.215 26.83C9.532 20.292.557 14.126 2.03 6.84 4.18-3.793 13.85 4.27 15.438 9.624"/><path d="M13.682 10.934c3.54-7.277 14.372-6.615 12.42-1.163-3.118 8.712-7.733 11.393-14.229 17.137"/></g></svg>
                                    <svg width="27" height="22" xmlns="http://www.w3.org/2000/svg"><g stroke="#979797" strokeWidth="2" fill="none" fillRule="evenodd"><path d="M1 20.483L23.923 2.34M19.858 1c-13.042 8.565-2.572 14.207 6.127 4.21"/></g></svg>
                                    <svg width="27" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="#979797" fill="none" fillRule="evenodd"><path d="M21.73 3.955C22.2 9.231 10.112 18.956 1 10.787"/><path d="M2.389 12.639c-2.16-2.16-1.409-4.77 2.256-7.83 1.727-1.565 4.062-2.42 6.324-2.983 7.34-1.828 10.99-.385 11.327 2.016" strokeWidth="2"/><path d="M4.477 7.826l-.191 1.988M7.283 6.042l3.839.207M8.835 8.243l.658 1.946M13.513 3.915l2.704.345M14.342 6.419l-.563 1.786M2.389 12.176c-1.02 5.261 9.722 7.407 10.185 1.389M4.082 13.794c-.066 2.497-.533 1.89 2.244.492M7.347 14.793c1.723 1.936 1.094 2.1 1.935-.25M14.594 12.613c.188 1.423 1.994.102 3.999-.9 1.131-.566 2.306-.27 3.338-1.193 1.124-1.006.43-2.84.828-3.9.61-1.622 1.613-2.374-1.36-2.968M3.315 15.417c0 9.722 27.778-3.241 19.444-8.797"/><path d="M4.704 18.194c-1.39 10.185 26.852-1.852 19.444-7.407" strokeWidth="2"/></g></svg>
                                 </div>
                              </div>
                              par
                              <a rel="noopener noreferrer" href="https://www.mediakod.com" title="Mediakod" className="mycopy" target="_blank">
                                 <i className="icon icon--mediakod"></i>
                              </a>
                           </div>
                        </div>
                     </div>
                  </footer>
               )
            }}
         />
      )
   }
}

export default Footer