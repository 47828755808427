import React from "react"
import { Link } from "gatsby"

// Helpers
import removeDomaine from "../../../helpers/url.helpers"

// Brand
import brandURL from "../../../images/brand/epicerie-logo.jpg"
import brand2xURL from "../../../images/brand/epicerie-logo@2x.jpg"

class Nav extends React.Component {
   state = {
      currentDisplay: "none",
      buttonState: "",
   }

   updateMenuState = () => {
      this.setState({
         currentDisplay:
            this.state.currentDisplay === "none" ? "block" : "none",
         buttonState:
            this.state.currentDisplay === "none" ? " button--active" : "",
      })
   }

   render() {
      return (
         <nav className="main-nav container">
            <Link to={`/`}>
               <img
                  src={brandURL}
                  srcSet={`${brandURL} 1x, ${brand2xURL} 2x`}
                  alt="L'épicerie"
                  className="brand"
               />
            </Link>

            <button
               className={`button button--unstyled button--menu${this.state.buttonState}`}
               onClick={this.updateMenuState}
            >
               Menu
            </button>

            <ul
               className="nav-primary"
               data-ui-component="primary-main"
               style={{ display: this.state.currentDisplay }}
            >
               {this.props.navItems.map((navItem, index) => (
                  <li key={`navItem-${index}`}>
                     <Link
                        to={`${removeDomaine(navItem.url)}`}
                        activeClassName={`current-nav-link`}
                     >
                        <span>{navItem.title}</span>
                     </Link>
                  </li>
               ))}
            </ul>
         </nav>
      )
   }
}

export default Nav
