import React from 'react'
import { StaticQuery, graphql } from 'gatsby';

// Components
import Nav from "../19-nav/Nav"
import InfoBox from "../../objects/InfoBox"

const Header = () => {
   return(
      <StaticQuery
         query={graphql`
            query {
               menu: allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "main"}}) {
                  edges {
                     node {
                        items {
                           url
                           title
                        }
                     }
                  }
               }
            }
         `}
         render={data => (
            <header className="site-header container">
               <InfoBox />

               <Nav navItems={data.menu.edges[0].node.items} />
            </header>
         )}
      />
   )
}

export default Header