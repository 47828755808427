import React from 'react'
import { StaticQuery, graphql } from 'gatsby';

const InfoBox = () => (
   <StaticQuery
      query={graphql`
         query {
            infobox: allWordpressAcfOptions {
               edges {
                  node {
                     informations_sites {
                        ep_facebook
                        ep_phone_link
                        ep_phone
                     }
                  }
               }
            }
         }
      `}

      render={data => {

         let infobox = data.infobox.edges[0].node.informations_sites

         return(
            <div className="infobox">
               {  infobox.ep_phone && (
                  <a href={`tel:${infobox.ep_phone_link}`}>
                     <i className="icon icon--phone"></i>
                     <span>{infobox.ep_phone}</span>
                  </a>
               )}
               
               { infobox.ep_facebook && (
                  <a href={infobox.ep_facebook}>
                     <i className="icon icon--facebook"></i>
                     <span>Message</span>
                  </a>
               )}
            </div>
         )
         
      }}
   />
)

export default InfoBox