import React from "react"
import PropTypes from "prop-types"

import Header from "./elements/20-header/Header"
import Footer from "./elements/21-footer/Footer"
import Messenger from "../components/objects/Messenger"
import CookieNotice from "../components/objects/CookieNotice"
import Cookies from "js-cookie"
import TagManager from "react-gtm-module"
import "./layout.css"

class Layout extends React.Component {
   constructor(props) {
      super(props)

      this.state = {
         allowStatistics: false,
         allowPreferences: false,
      }
   }

   componentDidMount() {
      if (typeof window !== "undefined") {
         // eslint-disable-next-line global-require
         require("smooth-scroll")('a[href*="#"]', {
            speed: 300,
         })
      }

      if (typeof document !== "undefined") {
         let mailTo = document.querySelectorAll('a[href^="mailto:"]')

         mailTo.forEach(mail => {
            mail.href = mail.href.replace("@", "%%%")
            mail.addEventListener(
               "click",
               () => (mail.href = mail.href.replace("%%%", "@"))
            )
         })
      }

      // Check cookie
      if (Cookies.get("rcl_preferences_consent") === "true") {
         this.setState({
            allowPreferences: true,
         })
      }

      if (Cookies.get("rcl_statistics_consent") === "true") {
         this.setState({
            allowStatistics: true,
         })

         if (typeof window !== "undefined") {
            if (
               !document
                  .querySelector("html")
                  .classList.contains("gtag-manager-init")
            ) {
               TagManager.initialize({
                  gtmId: "GTM-MD3QX3T",
               })
               document.querySelector("html").classList.add("gtag-manager-init")
            }
         }
      }
   }

   render() {
      return (
         <>
            <a
               className="screen-reader-text smoothscroll"
               href="#main"
               title="Passer au contenu principal"
            >
               Passer au contenu principal
            </a>

            <Header />

            {this.props.children}

            {this.state.allowPreferences && <Messenger />}

            <CookieNotice />

            <Footer />
         </>
      )
   }
}

Layout.propTypes = {
   children: PropTypes.node.isRequired,
}

export default Layout
