import React from 'react'

const TANote = props => (
  <svg width={149} height={85} {...props}>
    <g fill="none" fillRule="evenodd">
      <path stroke="#05A680" fill="#FFF" d="M.5.5h148v84H.5z" />
      <text fontFamily="Arial-BoldMT, Arial" fontSize={11} fontWeight="bold">
        <tspan x={19.479} y={44} fill="#252322">
          {'Consulter les avis de'}
        </tspan>
        <tspan x={39.037} y={56} fill="#252322">
          {'L\u2019\xE9picerie sur'}
        </tspan>
        <tspan x={46.076} y={68} fill="#252322">
          {'trip'}
        </tspan>
        <tspan x={63.795} y={68} fill="#05A680">
          {'advisor'}
        </tspan>
      </text>
      <g transform="translate(47 13)">
        <circle stroke="#05A680" cx={5.5} cy={5.5} r={5.5} />
        <circle fill="#05A680" cx={5.5} cy={5.5} r={3.5} />
      </g>
      <g transform="translate(58 13)">
        <circle stroke="#05A680" cx={5.5} cy={5.5} r={5.5} />
        <circle fill="#05A680" cx={5.5} cy={5.5} r={3.5} />
      </g>
      <g transform="translate(69 13)">
        <circle stroke="#05A680" cx={5.5} cy={5.5} r={5.5} />
        <circle fill="#05A680" cx={5.5} cy={5.5} r={3.5} />
      </g>
      <g transform="translate(80 13)">
        <circle stroke="#05A680" cx={5.5} cy={5.5} r={5.5} />
        <circle fill="#05A680" cx={5.5} cy={5.5} r={3.5} />
      </g>
      <g transform="translate(91 13)">
        <circle stroke="#05A680" cx={5.5} cy={5.5} r={5.5} />
        <path d="M5.5 9V2a3.5 3.5 0 000 7z" fill="#05A680" />
      </g>
    </g>
  </svg>
)

export default TANote